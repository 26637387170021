@import url(https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
  font-family: 'FontAwesome';
  src: url(/static/media/FontAwesome.0d2717cd.otf) format('opentype');
}

@font-face {
  font-family: 'Maax Bold';
  src: url(/static/media/Sans-Bold.10faad3b.ttf) format('truetype');
}

@font-face {
  font-family: 'Maax Regular';
  src: url(/static/media/Sans-Regular.84517bef.ttf) format('truetype');
}

@font-face {
  font-family: "Open Sans";
  src: url(/static/media/OpenSans-Regular.5a798cda.ttf)
}

@font-face {
  font-family: 'Raisonne AN DemiBold';
  src: url(/static/media/raisonne_demibold_an.dd1ada46.eot);
  src: url(/static/media/raisonne_demibold_an.dd1ada46.eot?#iefix) format('embedded-opentype'),
  url(/static/media/raisonne_demibold_an.54a4f035.woff) format('woff'),
  url(/static/media/raisonne_demibold_an.fe3e7845.ttf) format('truetype'),
  url(/static/media/raisonne_demibold_an.965ba8a6.svg#RaisonneANDemiBold) format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro-Regular.15d85a88.ttf);
}

@font-face {
  font-family: 'DINPro Bold';
  src: url(/static/media/DINPro-Bold.62430f54.ttf);
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter.ba8d1544.ttf);
}

button:focus, input:focus, select:focus {
  outline: 0;
}

button.link {
  border: 0;
  background: transparent;
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html, body, select, input {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;

  /*   Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
  -ms-touch-action: manipulation;
      touch-action: manipulation;
}

select::-ms-expand {
  display: none;
}

h1, h2 {
  font-weight: normal;
  font-style: normal;
}

h1 {
  font-size: 26px;
}

h3 {
  font-size: 18px;
  font-weight: normal;
}

html, body, #root {
  height: 100%;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a, .link {
  color: #1A1A1A;
  text-decoration: underline;
  cursor: pointer;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

/* prevent dotted outline in Firefox */
button::-moz-focus-inner {
  border: 0;
}

.center {
  text-align: center;
}

.widgetContent {
  min-width: 300px;
  padding: 20px 20px 20px 20px;
}

.widgetContent:first-child p {
  margin-top: 0;
}

.marginBottom {
  margin-bottom: 20px;
}

.marginBottomTen {
  margin-bottom: 10px;
}

.widgetContent:last-child p {
  margin-bottom: 0;
}

.invalidFlow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
}

.addressBox {
  display: inline-block;
}

.productTable {
  width: 100%;
  margin-bottom: 20px;
}

.alignRight {
  text-align: right;
}

.grey {
  color: #9B9B9B;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #c4c4c4;
  margin: 1em 0;
  padding: 0;
}

.centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
}

/* autoprefixer grid: autoplace */

.container, .containerLeft {
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 250px auto 1fr 100px;
  grid-template-rows: 250px auto 1fr 100px;
  -ms-grid-columns: 1fr 1fr 1fr 384px 1fr;
  grid-template-columns: 1fr 1fr 1fr 384px 1fr;
      grid-template-areas: "header header header header header" ". sidebar sidebar content ." ". . . . ." "footer footer footer footer footer";
}

.smallHeader {
  -ms-grid-rows: 60px auto 1fr 100px;
  grid-template-rows: 60px auto 1fr 100px;
}

.containerLeft {
  -ms-grid-columns: 1fr 384px 1fr 1fr 1fr;
  grid-template-columns: 1fr 384px 1fr 1fr 1fr;
      grid-template-areas: "header header header header header" ".  content sidebar sidebar ." ". . . . ." "footer footer footer footer footer";
}

.header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-area: header;
}

.contentTopRight, .contentTopLeft {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 4;
  -ms-grid-row-align: start;
      align-self: start;
  grid-area: content;
}

.contentBottomLeft, .contentBottomRight {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 4;
  -ms-grid-row-align: end;
      align-self: end;
  grid-area: content;
}

.sidebar {
  -ms-grid-row: 2;
  -ms-grid-column: 3;
  -ms-grid-column-span: 2;
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: sidebar;
}

.sidebarTopRight, .sidebarTopLeft {
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
  margin-top: 5px;
}

.sidebarBottomLeft, .sideBarBottomRight {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
}

.sidebarTopRight, .sideBarBottomRight {
  text-align: right;
}

.footer {
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  -ms-grid-row: 4;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-area: footer;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 384px 1fr 1fr 1fr;
  grid-template-columns: 1fr 384px 1fr 1fr 1fr;
      grid-template-areas: "blankLeft faq faq faq blankRight" "row2 row2 row2 row2 row2";
}

.faq {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 3;
  grid-area: faq;
}

.row2 {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  grid-area: row2;
}

.blankLeft {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: blankLeft;
  margin-top: 2.5em;
  background: white;
}

.blankRight {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: blankRight;
  margin-top: 2.5em;
  background: white;
}

/* Small Smartphone */
@media (max-width: 363px) {
  .container, .containerLeft {
    -ms-grid-rows: 100px 1fr auto 100px;
    grid-template-rows: 100px 1fr auto 100px;
    -ms-grid-columns: auto;
    grid-template-columns: auto;
        grid-template-areas: "header" "content" "sidebar" "footer";
  }

  .sidebar {
    margin-top: 20px;
  }

  .sidebarTopRight, .sidebarBottomLeft {
    text-align: left;
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .contentTopRight, .contentTopLeft {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  .contentBottomLeft, .contentBottomRight {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
  }
  .sidebar {
    -ms-grid-row: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .footer {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
}

/* Smartphone */
@media (min-width: 364px) and (max-width: 480px) {
  .container, .containerLeft {
    -ms-grid-rows: 100px 1fr auto 100px;
    grid-template-rows: 100px 1fr auto 100px;
    -ms-grid-columns: 4px auto 4px;
    grid-template-columns: 4px auto 4px;
        grid-template-areas: ". header ." ". content ." ". sidebar ." "footer footer footer";
  }

  .contentBottomLeft, .contentTopRight, .contentBottomRight, .contentTopLeft {
    -ms-flex-item-align: start;
        -ms-grid-row-align: start;
        align-self: start;
  }


  .footer {
    -ms-grid-columns: 20px auto;
    grid-template-columns: 20px auto;
        grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .contentTopRight, .contentTopLeft {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .contentBottomLeft, .contentBottomRight {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .sidebar {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  .footer {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }


  .faq {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }


  .row2 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }


  .blankLeft {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }


  .blankRight {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

/* iPad Portrait */
@media (min-width: 481px) and (max-width: 768px) {
  .container, .containerLeft {
    -ms-grid-rows: 150px 1fr auto 100px;
    grid-template-rows: 150px 1fr auto 100px;
    -ms-grid-columns: 20px 384px 1fr;
    grid-template-columns: 20px 384px 1fr;
        grid-template-areas: ". header header" ". content ." ". sidebar sidebar" "footer footer footer";
  }

  .smallHeader {
    -ms-grid-rows: 130px auto 1fr 100px;
    grid-template-rows: 130px auto 1fr 100px;
  }

  .contentBottomLeft, .contentTopRight, .contentTopLeft, .contentBottomRight {
    -ms-flex-item-align: end;
        -ms-grid-row-align: end;
        align-self: end;
  }

  .footer {
    -ms-grid-columns: 20px auto 20px;
    grid-template-columns: 20px auto 20px;
        grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }

  .sidebarTopRight, .sidebarBottomLeft {
    text-align: left;
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
  .contentTopRight, .contentTopLeft {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .contentBottomLeft, .contentBottomRight {
    -ms-grid-row: 2;
    -ms-grid-column: 2;
  }
  .sidebar {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
  .footer {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  .faq {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .row2 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  .blankLeft {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .blankRight {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

/* iPad Landscape */
@media (min-width: 769px) and (max-width: 1024px) {
  .container, .containerLeft {
    -ms-grid-rows: 150px 1fr 1fr 100px;
    grid-template-rows: 150px 1fr 1fr 100px;
    -ms-grid-columns: 20px auto 384px 20px;
    grid-template-columns: 20px auto 384px 20px;
        grid-template-areas: ". header header ." ". . . ." ". sidebar content ." "footer footer footer footer";
  }

  .smallHeader {
    -ms-grid-rows: 130px auto 1fr 100px;
    grid-template-rows: 130px auto 1fr 100px;
  }

  .containerLeft {
    -ms-grid-columns: 20px 384px;
    grid-template-columns: 20px 384px;
        grid-template-areas: ". header header" ". . ." ". content sidebar" "footer footer footer";
  }

  .footer {
    -ms-grid-columns: 20px auto 120px;
    grid-template-columns: 20px auto 120px;
        grid-template-areas: "blankLeft faq blankRight" "row2 row2 row2";
  }
  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }

  .header {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }
  .contentTopRight, .contentTopLeft {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }

  .contentTopRight, .contentTopLeft {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
  .contentBottomLeft, .contentBottomRight {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }

  .contentBottomLeft, .contentBottomRight {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
  }
  .sidebar {
    -ms-grid-row: 3;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .sidebar {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }
  .footer {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
  }

  .footer {
    -ms-grid-row: 4;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  .faq {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  .row2 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
  }

  .blankLeft {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }

  .blankRight {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
}

/* Large desktop */
@media (min-width: 1025px) {
  .header {
    margin-left: 20px;
  }


  .footer {
    -ms-grid-columns: 1fr 384px 1fr 1fr 1fr;
    grid-template-columns: 1fr 384px 1fr 1fr 1fr;
        grid-template-areas: "blankLeft faq faq blankRight blankRight" "row2 row2 row2 row2";
  }


  .faq {
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-column-span: 2;
  }


  .row2 {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
  }


  .blankLeft {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
  }


  .blankRight {
    -ms-grid-row: 1;
    -ms-grid-column: 4;
    -ms-grid-column-span: 2;
  }
}

